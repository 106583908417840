/**
 * Vue Store related to authentication.
 *
 * @author Documill
 */

import apiCall from './../../utils/api';
import LocalStorage from './../../utils/localStorage';
import { logger } from '../../utils/logger';
import Notification from './../../utils/notification';
import i18n from './../../i18n';

export default {

  state: {
    /**
     * User token to be used for API Call
     */
    token: '',

    /**
     * Authentication progress status
     * <loading | success | error>
     */
    status: '',

    organizationId: '',
  },

  getters: {
    /**
     * Returns value to determine if user is authenticated
     * if there is a Token
     */
    isAuthenticated: state => !!state.token,

    /**
     * Returns value of authentication status.
     */
    authStatus: state => state.status,

    organizationId: state =>state.organizationId,
  },

  mutations: {
    /**
     * Marks the beginning of authentication process
     */
    AUTH_REQUEST(state) {
      state.status = 'loading';
    },
    /**
     * Marks the success of authentication process
     * Saves Token to Vue Store
     */
    AUTH_SUCCESS(state, token) {
      state.status = 'success';
      state.token =token;
    },

    ORGANIZATION_SIGN_IN(state, organizationId) {
      state.organizationId = organizationId;
    },

    /**
     * Marks the failure of authentication process
     */
    AUTH_ERROR(state) {
      state.status = 'error';
    },
    /**
     * Removes Token from Vue Store
     */
    AUTH_LOGOUT(state) {
      state.status = '';
      state.token = '';
      state.organizationId='';
    },
  },

  actions: {
    async loadUserInformation(context) {
      let token = LocalStorage.getUserToken();

      if(!token) {
        return {
          state: "NOT_LOGGED_IN",
        };
      }

      apiCall.defaults.headers.common['Authorization'] = token;

      return await context.dispatch("getUserInfo").then(result => {
        // User was recognized
        context.commit("AUTH_SUCCESS",token);

        // Add information about the used organization names for the quick selection.
        // Note: See issue DOS-1172.
        // Note: Do the addition in nextTick so we do not trigger UI to update.
        // TODO: We should probably save the organization name only after user has succesfully
        //       signed in.

        let avatar = result.data.avatar;
        let organizationName = result.data.organization.name;
        let userName = result.data.fullName;
        let userEmail = result.data.email;
        // DOS-2118: Store identity provider id in localStorage.
        let identityProviderId = result.data.identityProviderId;
        let key = organizationName + userEmail + identityProviderId;

        let historyEntries = JSON.parse(LocalStorage.getHistoryEntries() || "{}");
        if(!historyEntries[key] && userEmail != null && userName != null) {
          historyEntries[key] = {
            "avatar": avatar,
            "organizationName": organizationName,
            "userName": userName,
            "userEmail": userEmail,
            "key": key,
            "identityProviderId": identityProviderId,
          };
          LocalStorage.setHistoryEntries(JSON.stringify(historyEntries));
        } else if (historyEntries[key]) {
          // DOS-2473: Update name after Sign in if a user is using different browsers.
          if(historyEntries[key].userName != userName) {
            historyEntries[key].userName = userName;
            LocalStorage.setHistoryEntries(JSON.stringify(historyEntries));
          }
          // DOS-2355: Broken avatar on Sign in screen.
          if (historyEntries[key].avatar != avatar) {
            historyEntries[key].avatar = avatar;
            LocalStorage.setHistoryEntries(JSON.stringify(historyEntries));
          }
        }

        return {
          state: "LOGGED_IN"
        };
      })
      .catch(error => {
        if(error.response &&
          (error.response.status == 401 || error.response.status == 403)) {
          // Unauthorized, show log in screen
          // LocalStorage.removeUserToken();
          // TODO: Chrome shows extra error message when receiving a 401 response
          console.warn("User token invalid or expired, need to log in again")
          context.dispatch("removeUserToken");
          return {
            state: "NOT_LOGGED_IN",
          };
        } else {
          // Should occur only if backend is down or broken
          console.error("Failed to contact server",error);
          throw error;
        }
      });
    },

    /**
     * Loads Guest user information and accepts invitation.
     *
     * TODO: This method is named a bit weirdly. The "accept invitation" seems to be the
     *       main action.
     *
     * Expected parameters:
     * {
     *   invitationId: String,
     *   taskId: String (optional)
     * }
     *
     * @param {*} context
     * @param {*} params
     * @returns
     */
    async loadGuestUserInformation(context,params) {
      if(context.getters.isAuthenticated) {
        context.commit("AUTH_LOGOUT");
        LocalStorage.getUserOrganizations();
      }

      let url = 'v1/invitation/' + params.invitationId + '/guest';
      if(params.taskId != null)
        url += '/' + params.taskId;

      return await apiCall.get(url)
        .then(result => {
          const projectId = result.data.projectId;
          const collaboratorId = result.data.collaboratorId;

          // FIXME: Do not use console.log.
          console.log("Accepted guest invitation for project: " + projectId);

          if(!collaboratorId)
            console.error("Did not get collaborator id for guest");

          // Set user token that will be read by loadUserInformation when called by router.js,
          // which will pass it to getUserInfo
          // The getUserInfo will also receive collaborator id again, and store it.

          LocalStorage.setUserToken(result.data.guestToken);

          return result.data;
        });
    },

    async loadTaskCollaboratorInformation(context,taskShareToken) {
      let vueInstance = this;
      return await apiCall.get('v1/tasks/task-share/' + taskShareToken + '/log-in')
        .then(result => {
          const projectId = result.data.projectId;
          const collaboratorId = result.data.collaboratorId;
          logger.debug("Logged in as task collaborator in project: " + projectId);

          if(!collaboratorId)
            logger.error("Did not get collaborator id for task collaborator");

          // Set user token that will be read by loadUserInformation when called by router.js,
          // which will pass it to getUserInfo
          // The getUserInfo will also receive collaborator id again, and store it.

          LocalStorage.setUserToken(result.data.authorizationToken);

          return result.data;
        })
        .catch(error => {
          UIkit.notification(i18n.global.t('auth.notification.task_collaborator_login_failure'),
                             {status:Notification.STATUS.DANGER});
          logger.error("Failed to log-in as task collaborator: " + error);
          throw error;
        });
    },

    /**
     * Log out before navigating to taskShareView.
     *
     * Additionally logs out taks-collaborator if trying to navigate anywhere.
     * This is done to block task-collaborator from navigating to other pages
     * than the "taskShareView".
     *
     * @param {*} context
     * @param {*} destination
     */

    async logOutWhenNavigatingToTaskShareView(context,destination){

      let token = LocalStorage.getUserToken();
      if(token){
        if(token.startsWith("flow-task-session") ||
          (!token.startsWith("flow-task-session") && destination.name == "taskShareView")){

          delete apiCall.defaults.headers.common['Authorization'];
          LocalStorage.clear();
          sessionStorage.removeItem("flowPageBeforeLogin");
          context.dispatch("clearUserInfo");
        }
      }
      return;
    },


    async checkForLoginToken(context) {

      // Already logged in.
      if(!this.getters.organizationId == "" || this.getters.isAuthenticated)
        return false;

      let registrationLoginToken = new URL(window.location.href).searchParams.get('loginToken');
      let appLoginToken = new URL(window.location.href).searchParams.get('appToken');

      if(registrationLoginToken || appLoginToken) { // New login detected

        let invitationId = new URL(window.location.href).searchParams.get('invitationToken');

        // Registration service, or an external Leap app, provided us with a short-lived single-use
        // login token, which we can use to authenticate ourselves to the
        // Flow backend, which will provide us with a session identified via
        // flow-user-token.

        let data = registrationLoginToken ? {
          loginToken: registrationLoginToken,
          invitationId: invitationId
        } : {
          loginToken: appLoginToken
        };

        // The method of authentication depends on whether we have a registration or an app token.
        let loginEndpoint = registrationLoginToken ? "v1/users/login" : "v1/users/app-login";

        return logIn(loginEndpoint,data,this);
      }
      else {
        return false;
      }

      // --- sub-methods ---

      function localStorageSetOrganization(resp) {
        let flowUser = JSON.parse(LocalStorage.getUserOrganizations());

        let organizationExistsLocalStorage = false;
        if (flowUser) {
          organizationExistsLocalStorage = flowUser.organizations.some(u => u.organizationId === resp.organizationId);
          if(!organizationExistsLocalStorage)
            flowUser.organizations.push(resp);
        } else {
          flowUser = {
            organizations: [resp]
          };
        }
        flowUser.activeOrgId = resp.organizationId;

        LocalStorage.setUserOrganizations(JSON.stringify(flowUser));
      }

      async function logIn(loginEndpoint,loginData,vueInstance) {

        return apiCall.post(loginEndpoint,loginData)
        .then(result => {
          let loginResult = result.data;

          vueInstance.commit('ORGANIZATION_SIGN_IN', loginResult.organizationId);
          LocalStorage.setUserToken(loginResult.token);
          localStorageSetOrganization(loginResult);
          return true; // Handled login
        })
        .catch(error => {
          if(error.response && error.response.data && error.response.data.deactivated) {

            let data = {
              redirectAfterLoginUrl: location.origin,
              organizationId: error.response.data.organizationId
            };

            // Start logging in by asking the backend for our login url.
            return apiCall.post("v1/users/loginInformation", data)
              .then(result => {
                let loginUrl =
                  window.FlowWebsiteGlobalConfig.basePaths.registrationWebsite+
                  "/sign_in.html?registrationSessionId="+
                  encodeURIComponent(result.data.registrationSessionId)
                  +"&errorMessage="+encodeURIComponent("Your account is deactivated from this organization.");
                  window.location.href = loginUrl;
              })
              .catch(error => {
                console.error("Failed to resolve log in url.",error);
                throw error;
              });
          }

          if(error.response.data && error.response.data.errorType) {
            sessionStorage.removeItem("flowPageBeforeLogin");

            switch(error.response.data.errorType) {
              case "ENFORCE_IDENTITY_PROVIDER":
                return "ENFORCE_IDENTITY_PROVIDER";
              case "UNAUTHORIZED_IN_ORGANIZATION":
                return "UNAUTHORIZED";
              default:
                return "ERROR";
            }
          }

          // See FLOW-1990
          if(error.response.status == 403) {
            UIkit.notification(error.response.data.message,{status:Notification.STATUS.WARNING});
          }

          return "ERROR";
        });
      }

    },

    logOutTaskCollaborator(context){
      delete apiCall.defaults.headers.common['Authorization'];
      LocalStorage.clear();
      sessionStorage.removeItem("flowPageBeforeLogin");
      context.dispatch("clearUserInfo");
    },

    /**
     * Logout user by:
     * - Calling logout in backend to clear tokens from database
     * - Removing API call header for authorization
     * - Removing the Token from Vuex Store
     * - Removing user and organization info from Vuex Store
     *
     * NOTE: This method doesn't remove tokens from LocalStorage!
     *       The tokens that need to be removed depend on the logout
     *       (see authLogout(), authLogoutOrg)
     */
    async logout(context) {
      function clearSensitiveData() {
        delete apiCall.defaults.headers.common['Authorization'];
        sessionStorage.removeItem("flowPageBeforeLogin");
        // Removing the Token from Vue Store
        context.commit('AUTH_LOGOUT');
        context.dispatch('clearUserInfo');
        context.dispatch("clearOrgInfo");
      }

      // The user may already be logged out in another browser tab (DOS-3739)
      if(!LocalStorage.getUserToken())
        return; // Do nothing

      try {
        // Calling logout in backend to clear token from database
        const result = await apiCall.post('v1/users/logout');

        clearSensitiveData();
        logger.debug("Query logout succeeded with result:", result);
      } catch(error) {
        context.commit('AUTH_ERROR', error);
        // if the request fails, remove any possible user token if possible
        clearSensitiveData();
        logger.error("Query logout didn't succeed with message:", error);
      }
    },

    /**
     * Logout a user and remove all user/organization tokens from LocalStorage.
     */
    async authLogout(context) {
      await context.dispatch('logout');

      LocalStorage.clear();
    },

    /**
     * Logout a user from an organization and remove org token from LocalStorage.
     */
    async authLogoutOrg(context, orgId) {
      await context.dispatch('logout');

      LocalStorage.removeUserToken();
      LocalStorage.removeOrgToken(orgId);
    },

    removeUserToken(context) {
      LocalStorage.clear();
      context.commit("AUTH_LOGOUT");
    },

    resetAuthStore(context) {
      context.commit("AUTH_LOGOUT");
    },

    async redirectToLogin(context, params) {

      let organizationId = params.organizationId;
      let invitationId = params.invitationId;
      let loginname = params.loginname;

      let data = {
          redirectAfterLoginUrl: location.origin,
          organizationId: organizationId,
          invitationId: invitationId
      };

      // TODO: An existing user might be redirected from an email with invitation from router.js.
      // Should we confirm that the invitation has not been accepted? (see comment FLOW-4062)

      // Start logging in by asking the backend for our login url.
      return apiCall.post("v1/users/loginInformation", data)
        .then(result => {
          let loginUrl =
            window.FlowWebsiteGlobalConfig.basePaths.registrationWebsite+
            "/sign_in.html?registrationSessionId="+
            encodeURIComponent(result.data.registrationSessionId);

          if(loginname){
            loginUrl = loginUrl +  "&loginname=" + encodeURIComponent(loginname);
          }

          if(invitationId) {
            loginUrl += '&invitation=true';
          }

            console.log("Redirecting to login, then coming back to",
                    sessionStorage.getItem("flowPageBeforeLogin"));
            sessionStorage.setItem("flow-organization",organizationId);
            window.location.href = loginUrl;
        })
        .catch(error => {
           console.error("Failed to resolve log in url. Is server down?",error);
          throw error;
        });
    },


    /**
     * Call for the information to login with the external application
     */
    async getExternalAppLoginInfo(context, params) {

      let url = 'v1/external/external-app-login-urls?';

      if(params.taskId != null)
        url += 'taskId=' + params.taskId;
      else if(params.projectId != null)
        url += 'projectId=' + params.projectId;
      else if(params.organizationId != null)
        url += 'organizationId=' + params.organizationId;

      return await apiCall.get(url)
        .then(result => {
          // add custom parameters to external app url
          result.data.externalApps.forEach(app => {
            if(app.externalApp === 'SALESFORCE_LEAP_APPLICATION')
              app.externalAppUrl = app.externalAppUrl + '?c__leapPath=' +
                encodeURIComponent(params.pageBeforeLogin);
          });
          return result.data;
        })
        .catch(error => {
          console.error("Failed to resolve get external app login information url. ",error);
          throw error;
        });
    },


    /**
     *  Redirects the currently logged-in user to sign-only website.
     */
    async redirectToSignOnlyWebsite(context, params) {

      if(!window.FlowWebsiteGlobalConfig.basePaths.flowSignOnlyWebsite) {
        // Don't redirect if the Sign-Only service is not configured.
        logger.warn('Sign-Only website is not configured.')
        return;
      }

      // Get the single use login-token that will be passed to sign-only-website for log-in.
      let url = 'v1/users/sign-only-login-token';

      return await apiCall.get(url)
        .then(result => {

          let token = result.data.token;
          let organizationId = this.getters.getUserOrganizationId
          let redirectUrl;
          if(params.pathName === 'projectTasks') {
            redirectUrl =
              window.FlowWebsiteGlobalConfig.basePaths.flowSignOnlyWebsite+
              "/"+organizationId+"/sign/"+params.projectId+"?login-token="+
                  encodeURIComponent(token);
          }
          else {
            redirectUrl =
              window.FlowWebsiteGlobalConfig.basePaths.flowSignOnlyWebsite+
              "/"+organizationId+"/home?login-token="+
                  encodeURIComponent(token);
          }

          logger.info("Redirecting to Sign-Only website.");
          window.location.href = redirectUrl;
        })
        .catch(error => {

           // Clear the Flow website local storage and login-token.
           delete apiCall.defaults.headers.common['Authorization'];
           LocalStorage.clear();
           sessionStorage.removeItem("flowPageBeforeLogin");
           context.dispatch("clearUserInfo");

          logger.error("Failed to redirect to sing-only website. ",error);
          throw error;
        });
    },


    /**
     *  Redirects the currently logged-in user to sign-only website's OAuth integration flow.
     */
    redirectToSignOnlyOAuth(context, query) {

      if(!window.FlowWebsiteGlobalConfig.basePaths.flowSignOnlyWebsite) {
        // Don't redirect if the Sign-Only service is not configured.
        logger.warn('Sign-Only website is not configured.')
        return;
      }

      if(query == null || !query.state || !query.state.startsWith('sign-only.')) {
        // No valid query parameters?
        logger.error("Failed to redirect to Sign-Only website for the OAuth flow.");
        throw new Error("Missing valid OAuth integration query parameters.");
      }

      // Carry over the query parameters to the redirect URL.
      let queryParams = [];

      for(const key of Object.keys(query)) {
        if(key != null) {
          const value = query[key] != null ? query[key] : '';
          queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
      }

      logger.info("Redirecting to Sign-Only OAuth integration page.");
      window.location.href =
        window.FlowWebsiteGlobalConfig.basePaths.flowSignOnlyWebsite +
        '/oauth-integration?' + queryParams.join('&');
    }
  }
}
